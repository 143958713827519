//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

body {
  background-color: $primary-3;
}

.stars {
  background: url(../img/decorations/stars.svg) center;
  background-size: cover;
}

.lift {
  transition-duration: .2s;
  &:hover {
    transform: translate3d(0,-6px,0);
  }
}

.article > p {
  font-size: 15pt;
}

// No rounded corners
.no-round {
  &-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
